import React, { Suspense, lazy } from "react";
import {
  withRouter,
  Route,
  Switch,
} from "react-router-dom";

//STATE
//UTILS
import "./App.scss";

//LAYOUT
const Header = lazy(() => import("./components/layout/Header"));
const Footer = lazy(() => import("./components/layout/Footer"));

//PAGE
const Home = lazy(() => import("./components/pages/Home/Home"));

const App = () => {
  return (
    <div className="wrappage">
      <Suspense fallback={<></>}>
        <Header />
        <div className="wrappage-inner">
          <Switch>
            <Route exact path="/" component={Home} />
          </Switch>
        </div>
        <Footer />
      </Suspense>
    </div>
  );
};

export default withRouter(App);
